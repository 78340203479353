import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

// Components
import Seo from '../components/Seo';

import { stripProtocol, shorten } from '../utils/helpers';

export default function InvestmentTemplate({
  data,
}) {
  const { markdownRemark } = data;
  const { fields, frontmatter, html } = markdownRemark;
  let description = html ? html : '';
  let image = frontmatter.logo ? frontmatter.logo.publicURL : null;
  let website = frontmatter.website ? stripProtocol(frontmatter.website) : null;
  let facebook = frontmatter.facebook ? stripProtocol(frontmatter.facebook) : null;
  let twitter = frontmatter.twitter ? stripProtocol(frontmatter.twitter) : null;
  let linkedin = frontmatter.linkedin ? stripProtocol(frontmatter.linkedin) : null;
  return (
    <section className="bg-white mx-auto py-12 lg:pb-20 px-4 sm:px-6 lg:px-8 max-w-lg sm:max-w-2xl md:max-w-4xl lg:max-w-7xl">
      <header>
        <h1 className="sr-only">Investment: {frontmatter.title}</h1>
      </header>
      <div className="md:grid md:grid-cols-5 gap-x-6 lg:gap-x-12">

        <div className="md:col-span-5 pb-4">
          <Link to='/investments/'>
            Investments
          </Link>
          &nbsp;&raquo; {frontmatter.title}
        </div>

        {/* Content */}
        <div className="md:col-span-3 pb-4">
    			<div>
            {frontmatter.logo.childImageSharp
              && (
                <div className="py-8">
                  <GatsbyImage
                    image={frontmatter.logo.childImageSharp.gatsbyImageData}
                    alt={frontmatter.title}
                    backgroundColor="transparent"
                  />
                </div>
              )
            }
            <div
              className="content text-block"
              dangerouslySetInnerHTML={{ __html: html }}
            />
    			</div>
        </div>

        {/* Sidebar */}
        <div className="md:col-span-2 pb-4">
          <dl className="grid grid-cols-1 sm:grid-cols-5 text-sm max-w-xs md:max-w-none overflow-hidden">
          {website
            && (
              <>
                <dt className="sm:col-span-2 lg:col-span-1 font-bold">Website:</dt>
                <dd className="sm:col-span-3 lg:col-span-4 pb-4 sm:pb-2"><a rel="nofollow" href={frontmatter.website}>{shorten(website, 25, '')}</a></dd>
              </>
            )
          }
          {twitter
            && (
              <>
                <dt className="sm:col-span-2 lg:col-span-1 font-bold">Twitter:</dt>
                <dd className="sm:col-span-3 lg:col-span-4 pb-4 sm:pb-2"><a rel="nofollow" href={frontmatter.twitter}>{shorten(twitter, 20, '')}</a></dd>
              </>
            )
          }
          {linkedin
            && (
              <>
                <dt className="sm:col-span-2 lg:col-span-1 font-bold">LinkedIn:</dt>
                <dd className="sm:col-span-3 lg:col-span-4 pb-4 sm:pb-2"><a rel="nofollow" href={frontmatter.linkedin}>{shorten(linkedin, 20, '')}</a></dd>
              </>
            )
          }
          {facebook
            && (
              <>
                <dt className="sm:col-span-2 lg:col-span-1 font-bold">Facebook:</dt>
                <dd className="sm:col-span-3 lg:col-span-4 pb-4 sm:pb-2"><a rel="nofollow" href={frontmatter.facebook}>{shorten(facebook, 20, '')}</a></dd>
              </>
            )
          }
          </dl>
        </div>

      </div>
      <Seo
        slug={fields.slug}
        title={`${frontmatter.title} (Investment)`}
        description={description}
        image={image}
      />
    </section>
  );
}

InvestmentTemplate.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      fields: PropTypes.shape({
        slug: PropTypes.string.isRequired,
      }).isRequired,
      frontmatter: PropTypes.shape({
        title: PropTypes.string.isRequired,
        website: PropTypes.string,
        facebook: PropTypes.string,
        twitter: PropTypes.string,
        linkedin: PropTypes.string,
        logo: PropTypes.shape({
          publicURL: PropTypes.string,
          childImageSharp: PropTypes.shape({
            gatsbyImageData: PropTypes.shape({}),
          }),
        }),
      }).isRequired,
      html: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

InvestmentTemplate.defaultProps = {};

export const pageQuery = graphql`
  query InvestmentByPath($path: String!) {
    markdownRemark(
      fields: {
        slug: {
          eq: $path
        }
      }
    ) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        blurb
        website
        facebook
        twitter
        linkedin
        logo {
          publicURL
          childImageSharp {
            gatsbyImageData(
              width: 280,
              placeholder: NONE
            )
          }
        }
      }
    }
  }
`;
